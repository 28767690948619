import Head from 'next/head'
import { Col, Container, Row } from "react-bootstrap";

export default function Custom404() {
  return (
    <Container className="vh-75 d-flex justify-content-center align-items-center">
      <Head>
        <title>Wildcloud Console - 404</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Row>
        <Col>
          <h1 className="text-center">404 - Page not found</h1>
        </Col>
      </Row>
    </Container>
  )
}
